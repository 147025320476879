<template>
  <div class="yarn mt-4">
    <div class="yarn_add" style="max-height: 300px; overflow: auto">
      <label class="form-label fs-18">{{ $t("yarn.name") }}</label>
      <div class="card-header" style="padding: 0.2rem 1rem"></div>
      <!-- 选中信息 -->
      <div class="yarn_form">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th
                style="width: 20%"
                v-for="(head, index) in dataHeadList"
                :key="index"
              >
                {{ head }}
              </th>
            </tr>
          </thead>
          <tbody v-if="selectedList">
            <tr
              v-for="(info, index) in selectedList"
              :key="index"
              style="vertical-align: middle"
            >
              <th scope="row">
                {{ index + 1 }}
              </th>
              <td class="id">{{ info.yarn_id }}</td>
              <td class="element">
                <input
                  type="text"
                  class="form-control"
                  v-model="info.persentage"
                />
              </td>
              <td class="type">{{ info.type }}</td>
              <td class="action">
                <div
                  class="d-inline-block text-danger cursor"
                  @click="deleteClick(info)"
                >
                  <i class="fs-16 ri-delete-bin-5-fill"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-header" style="padding: 0.2rem 1rem"></div>
    <!-- 搜索 -->
    <div class="d-flex justify-content-sm-end mt-5">
      <div class="search-box ms-2">
        <input
          type="text"
          class="form-control"
          v-model="keyword"
          :placeholder="$t('base.search_id')"
          @keyup.enter="searchInput"
        />
        <i class="ri-search-line search-icon"></i>
      </div>
    </div>
    <!-- 列表 -->
    <div
      class="mt-3 border-top-dashed border-1 border-light"
      style="max-height: 500px; overflow: auto"
    >
      <table class="table table-bordered table-nowrap mt-3">
        <thead class="table-light">
          <tr>
            <th v-for="(head, index) in tableHeadList" :key="index">
              {{ head }}
            </th>
          </tr>
        </thead>
        <tbody style="vertical-align: middle">
          <tr v-for="(info, index) in tableData" :key="index">
            <td scope="row" class="text-center">
              {{ info.number }}
            </td>
            <td>
              <a href="javascript:;" @click="handlerJump(info.id)">{{
                info.yarn_id
              }}</a>
            </td>
            <td class="text-center">{{ info.type }}</td>
            <td>{{ info.supplier_name }}</td>
            <td>{{ info.brand_name }}</td>
            <td>{{ info.price }}</td>
            <td>{{ info.diameter }}</td>
            <td>{{ info.linear_density }}</td>
            <td>
              <div v-if="!info.isExist" @click="addClick(info)">
                <i class="ri-add-circle-fill fs-16 text-secondary cursor"></i>
              </div>
              <div v-else>
                <i class="ri-check-fill fs-16 text-success"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!tableData.length" style="position: relative; height: 120px">
      <Empty v-if="!tableDataLoading" flag="1"></Empty>
      <Loading v-else></Loading>
    </div>
    <Pagination
      v-if="pagination.total"
      v-model:page="pagination.currentPage"
      v-model:perPage="pagination.pageSize"
      :pages="pagination.pageOption"
      :prePageOption="pagination.pageSizeOption"
      :tab_info_length="pagination.total"
      class="me-5 my-3"
    >
    </Pagination>
  </div>
</template>

<script>
import _ from "lodash";
import { getYarnOptions } from "@/service/index";
import { tableMixin } from "@/mixins/Table1.js";
export default {
  emits: ["update:selectedList"],
  props: {
    selectedList: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [tableMixin],
  data() {
    return {
      dataHeadList: [
        this.$t("attribute.number"),
        this.$t("yarn.id"),
        this.$t("yarn.percentage") +
          " (" +
          this.$t("yarn.percentage_unit") +
          ")",
        this.$t("yarn.type"),
        this.$t("base.operation"),
      ],
      tableHeadList: [
        this.$t("base.No"),
        this.$t("attribute.id"),
        this.$t("attribute.type"),
        this.$t("attribute.supplier"),
        this.$t("attribute.brand"),
        this.$t("attribute.price"),
        this.$t("attribute.diameter"),
        this.$t("attribute.density"),
        this.$t("base.operation"),
      ],
      keyword: "",
    };
  },
  mounted() {
    this.getFirstPage();
  },
  methods: {
    handlerJump(id) {
      this.$router.push(`/yarn`);
      setTimeout(() => {
        this.$router.push(`/yarn_detail/?id=${id}&type=view`);
      });
    },
    deleteClick(item) {
      let data = _.cloneDeep(this.selectedList);
      data = data.filter((info) => {
        return info.id != item.id;
      });
      this.$emit("update:selectedList", data);
      this.tableData.forEach((titem) => {
        if (titem.id === item.id) {
          titem.isExist = false;
        }
      });
    },
    searchInput() {
      this.params.keyword = this.keyword;
      this.getFirstPage();
    },
    addClick(item) {
      if (item.isExist) {
        return;
      }
      let info = {
        id: item.id,
        persentage: "",
        type: item.type,
        yarn_id: item.yarn_id,
      };
      let data = _.cloneDeep(this.selectedList);
      data.push(info);
      this.$emit("update:selectedList", data);
      item.isExist = true;
    },
    requestList(params) {
      getYarnOptions(params)
        .then((res) => {
          if (res && res.payload) {
            this.updateData(res.payload);
            this.tableData.forEach((item) => {
              item.isExist = _.some(this.selectedList, { id: item.id });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    keyword(val) {
      if (!val) {
        this.searchInput();
      }
    },
  },
};
</script>

<style>
</style>
